import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Head from '../components/head';
import Footer from '../components/footer';

const FreeBook = ({ sourceName, sourceValue }) => {
  const PAGE = "Sign Up";
  const listFields = { [sourceName]: sourceValue }; //* Required for MailChimp

  const [bookCoverLoaded, setBookCoverLoaded] = useState(false);
  const [posterLoaded, setPosterLoaded] = useState(false);

  useEffect(() => {
    loadCoverImage();
    loadPosterImage();
  });

  const loadCoverImage = () => {
    if (bookCoverLoaded) return;

    const imageUrl = require(`../images/coverThumbNails/Deceit_3D_PB_CW_Rotation.png`);
    let imgContainer = document.querySelector(".book-cover-container");

    if (!imgContainer) return;

    imgContainer.classList.add("loading");
    let preloadedCover = document.createElement("img");
    preloadedCover.src = imageUrl;
    preloadedCover.alt = `Deceit Cover`
    preloadedCover.style.width = '300px';

    preloadedCover.addEventListener('load', (event) => {
      imgContainer.classList.remove("loading");
      imgContainer.appendChild(preloadedCover)
      preloadedCover = null;
    });

    setBookCoverLoaded(true);
  }

  const loadPosterImage = () => {
    if (posterLoaded) return;

    const imageUrl = require(`../images/DezmaraPosterThumb.png`);

    let imgContainer = document.querySelector(".poster-container");

    if (!imgContainer) return;

    imgContainer.classList.add("loading");
    let preloadedPoster = document.createElement("img");
    preloadedPoster.src = imageUrl;
    preloadedPoster.alt = `Dezmara Poster`;
    preloadedPoster.className = 'poster-image'
    preloadedPoster.style.width = '240px';

    preloadedPoster.addEventListener('load', (event) => {
      imgContainer.classList.remove("loading");
      imgContainer.appendChild(preloadedPoster)
      preloadedPoster = null;
    });

    setPosterLoaded(true);
  }

  const handleEnterEmail = () => {
    const notice = withReactContent(Swal);
    notice.fire({
      background: '#43209d',
      confirmButtonColor: '#F3BC0B',
      width: '40rem',
      scrollbarPadding: true,
      showCancelButton: true,
      confirmButtonText: 'JOIN',
      cancelButtonText: 'ABORT',
      reverseButtons: true,
      title: 'Just tell me where to send your swag!',
      html: `<span class="white-text small-font">Enter your email to get your free book & poster sent to your
      inbox. You'll also be added to my VIP Readers' Group, where you'll be the
      first to know about important events and updates, as well as limited edition books,
      artwork, and more</span>. <span class="highlight-yellow">I'll NEVER spam you (because spam sucks), 
      your info is absolutely private, and you can unsubscribe at any time</span>.`,
      input: 'email',
      inputPlaceholder: 'Email address'
    }).then((result) => {
      if (result.isConfirmed) {
        handleSubmit(result.value);
        console.log('result:', result);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        cancelJoinConfirmation();
      }
    }).catch(err => {
      console.error(err);
      fireGenericFailureNotice();
    })
  }

  const handleSubmit = async (email) => {
    const resultsPayload = await addToMailchimp(email, listFields);
    console.log('Results:', resultsPayload);
    if (resultsPayload.result === 'success') {
      fireSuccessNotice();
    } else {
      fireFailureNotice(resultsPayload);
    }
  }

  const fireSuccessNotice = () => {
    const notice = withReactContent(Swal);
    notice.fire({
      icon: 'success',
      title: 'Aw, yeah...',
      background: '#43209d',
      confirmButtonColor: '#F3BC0B',
      width: '40rem',
      scrollbarPadding: true,
      text: `We're almost there! Simply confirm your email address using
      the link I just sent you, and we're all done.`,
    }).then(() => {
      //* Unhide the nav button, dude.
      const hiddenButton = document.querySelector('.toggleable');
      hiddenButton.classList.add('show');
    })
  }

  const cancelJoinConfirmation = () => {
    const notice = withReactContent(Swal);
    notice.fire({
      icon: 'error',
      title: 'Mission Aborted.',
      background: '#43209d',
      confirmButtonColor: '#F3BC0B',
      width: '40rem',
      scrollbarPadding: true,
      html: `Sorry things didn't work out. <span class="highlight-white">
      This site does NOT store your info</span>, and you won't
      get any notifications from Sean Allen. Cheers!`,
    })
  }

  const disclaimerNotice = () => {
    const notice = withReactContent(Swal);
    notice.fire({
      icon: 'info',
      title: 'Heads up...',
      background: '#43209d',
      confirmButtonColor: '#F3BC0B',
      width: '40rem',
      scrollbarPadding: true,
      showCancelButton: true,
      confirmButtonText: 'JOIN',
      cancelButtonText: 'ABORT',
      reverseButtons: true,
      html: `<span class="highlight-white">The D-Evolution blends space, magic, swords,
      guns, and technology</span>. There's also foul language and violence. 
      If you're still keen to press on, welcome aboard. If not, thanks for visiting!`
    }).then((result) => {
      if (result.isConfirmed) {
        handleEnterEmail();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        cancelJoinConfirmation();
      }
    })
  }

  const fireFailureNotice = (results) => {
    const notice = withReactContent(Swal);
    notice.fire({
      icon: 'error',
      title: 'Oops...',
      background: '#43209d',
      confirmButtonColor: '#F3BC0B',
      width: '40rem',
      scrollbarPadding: true,
      html: `<span class="alert-wrapper">${results.msg}</span>`,
    })
  }

  const fireGenericFailureNotice = () => {
    const notice = withReactContent(Swal);
    notice.fire({
      icon: 'error',
      title: 'Oops...',
      background: '#43209d',
      confirmButtonColor: '#F3BC0B',
      width: '40rem',
      scrollbarPadding: true,
      text: `Something went wrong. Please try again.`,
    })
  }

  const inactiveFormNotice = () => {
    const notice = withReactContent(Swal);
    notice.fire({
      icon: 'error',
      title: 'Oops...',
      background: '#43209d',
      confirmButtonColor: '#F3BC0B',
      width: '40rem',
      scrollbarPadding: true,
      text: `This feature isn't live yet. Stay tuned!`,
    })
  }

  const redirectToHome = () => {
    window.location = '/';
  }

  return (
    <section className="list-sign-up">
      <Head title={PAGE} />
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>

      <div className="static-header-bar">
        <ul>
          <li className="logo"><span className="text-logo">Sean Allen</span></li>
        </ul>
      </div>
      <section className="signup-form-wrapper light">
        <section className="offer-and-input">
          <div className="newsletter-incentive-blurb">
            <div className="review">
              <div className="review__text">
                "An explosive debut novel...leaving a trail of genre-bending destruction in its wake."
              </div>
              <span className="review__reviewer-tag">&mdash;Jake Hasson, RantingDragon.com</span>
            </div>
            <h3 className="highlight-yellow gray-drop-shadow">
              A universe on the edge of crumbling. A human who shouldn't exist.
              One last chance to save the stars.
            </h3>
            <p>
              The last of her kind. Lost without memory. Caught in the orbit of a
              deadly military operation, can this human smuggler hope to survive?
            </p>
            <p>
              Dark, gritty, and relentlessly paced. Deceit is the pulse-pounding first
              book in The D-Evolution space opera fantasy series.
            </p>
            <p>
              Original artwork 6" x 9" digital mini-poster. Looks swell printed on photo paper!
            </p>
          </div>
          <div className="input-controls full-size">
            <button
              disabled={false}
              className="btn yellow inline-block"
              onClick={() => disclaimerNotice()}>Get My FREE Book + Poster!
            </button>
            <button
              disabled={false}
              className="btn yellow inline-block toggleable"
              onClick={() => redirectToHome()}>Go Home
            </button>
          </div>
        </section>
        <div className="newsletter-incentive-graphics">
          <div className="book-cover-container loading">
            {/* //* image dynamically inserted */}
          </div>
          <div className="plus-sign gray-drop-shadow">+</div>
          <div className="poster-container loading">
            {/* //* image dynamically inserted */}
          </div>
        </div>
        <div className="input-controls mobile">
          <div className="field-and-submit">
            <button
              disabled={false}
              className="btn yellow"
              onClick={() => disclaimerNotice()}>Get My FREE Book + Poster!
            </button>
            <button
              disabled={false}
              className="btn yellow toggleable"
              onClick={() => redirectToHome()}>Go Home
            </button>
          </div>
        </div>
      </section>
      <Footer />
    </section>
  )
}

export default FreeBook;