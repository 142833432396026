import React from 'react';
import FreeBook from '../components/freeBook';
import '../styles/index.scss';


const FreeBookOffer = () => {
  return (
    <FreeBook
      sourceName='group[80976][2]'
      sourceValue='2'
    />
  )
}

export default FreeBookOffer;
